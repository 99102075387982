import utilsService from "@/mixins/utils/axios/utils.service";
const rgcEndpoint = `${process.env.VUE_APP_BASE_URL}/admin/rgc`;

class RgcService {
    listAllRgc () {
        return utilsService.getRequest(`${rgcEndpoint}`);
    }
    updateRgc (...args) {
        return utilsService.putRequest(`${rgcEndpoint}/update`, ...args);
    }
    deleteRgc (id) {
        return utilsService.deleteRequest(`${rgcEndpoint}/delete`, {id: id});
    }
    insertNewRgc (...args) {
        return utilsService.postRequest(`${rgcEndpoint}/insert`, ...args);
    }
}

export default new RgcService()
