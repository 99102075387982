<template>
  <div>
    <b-table-custom :table-data="dataObjectList"
                    :table-columns="columns"
                    :is-data-loading="isTableDataLoading"
    >
      <template #button_event>
        <b-button size="sm" variant="primary" @click="openModal('modal-create')">
          <i class="fas fa-plus mr-50"/>
          <span>Thêm Mới</span>
        </b-button>
      </template>
      <template #menu="data">
        <template v-for="menu in menuButtonItems">
          <b-dropdown-item @click="menu.onClick(data.item)">
            <i :class="`${menu.icon} text-${menu.color} mr-50`"/>
            <span>{{ menu.label }}</span>
          </b-dropdown-item>
        </template>
      </template>
    </b-table-custom>

    <custom-modal id="rgc-modal"
                   ref="rgc-modal"
                    :title="currentModal.title"
                    :ok-title="currentModal.okTitle"
                    :cancel-title="currentModal.cancelTitle"
                    :variant="currentModal.color"
                    :is-manual-close="currentModal.manualClose"
                    @ok="currentModal.confirmAction"
    >
      <b-card-text v-if="modalName === 'modal-update' ||  modalName === 'modal-create'">
        <b-row>
          <b-col md="6" cols="12">
            <custom-input v-model="dataObjectSelectedClone['domain']" label="Domain" />
          </b-col>
          <b-col md="6" cols="12">
            <custom-input v-model="dataObjectSelectedClone['lev_domain']" label="LEV Domain" />
          </b-col>
        </b-row>
        <custom-input type="number" v-model="dataObjectSelectedClone['lev_rgc_service_id']" label="LEV ID" />
        <custom-input v-model="dataObjectSelectedClone['location']" label="Location" />
        <custom-input v-model="dataObjectSelectedClone['note']" label="Note" />
        <switch-button v-model="dataObjectSelectedClone['is_active']" true-label="Active" false-label="Inactive"/>
      </b-card-text>
      <b-card-text v-if="modalName === 'modal-delete'">
        <p>Bạn có chắc chắn muốn xoá RGC location <strong>{{ dataObjectSelectedClone['location'] }}</strong>?</p>
      </b-card-text>
    </custom-modal>
  </div>
</template>

<script>
import InputHelper from "@/mixins/utils/inputHelper";
import DataRequester from "@/mixins/utils/dataRequester";
import DataObjectList from "@/mixins/utils/dataObjectList";
import rgcService from "@/mixins/utils/axios/rgc.service";

import BTableCustom from "@/views/components/table/BTableCustom.vue";
import CustomModal from "@/views/components/CustomModal.vue";
import CustomInput from "@/views/components/form/CustomInput.vue";
import SwitchButton from "@/views/components/form/SwitchButton.vue";

export default {
  name: "RGC",
  mixins: [InputHelper, DataRequester, DataObjectList],
  components: {
    SwitchButton,
    BTableCustom,
    CustomModal,
    CustomInput
  },
  computed: {
    menuButtonItems: function () {
      let self = this
      return [
        {
          label: 'Cập nhật',
          icon: 'fas fa-edit',
          color: 'primary',
          onClick: function (data) {
            self.openModal('modal-update', data)
          }
        },
        {
          label: 'Xoá',
          icon: 'fas fa-trash-alt',
          color: 'danger',
          onClick: function (data) {
            self.openModal('modal-delete', data)
          }
        }
      ]
    },
    currentModal: function () {
      switch (this.modalName) {
        case 'modal-update':
          return {
            title: 'Cập nhật thông tin',
            okTitle: 'Cập nhật',
            cancelTitle: 'Đóng',
            color: 'primary',
            manualClose: true,
            confirmAction: this.updateRgc
          }
        case 'modal-delete':
          return {
            title: 'Xoá thông tin',
            okTitle: 'Xoá',
            cancelTitle: 'Đóng',
            color: 'danger',
            manualClose: true,
            confirmAction: this.deleteRgc
          }
        case 'modal-create':
          return {
            title: 'Thêm mới',
            okTitle: 'Thêm',
            cancelTitle: 'Đóng',
            color: 'success',
            manualClose: true,
            confirmAction: this.insertNewRgc
          }
        default:
          return {
            confirmAction: function () {}
          }
      }
    }
  },
  data () {
    return {
      columns: [
        {key: 'domain', label: 'Domain'},
        {key: 'lev_domain', label: 'LEV Domain'},
        {key: 'lev_rgc_service_id', label: 'LEV ID'},
        {key: 'location', label: 'Location'},
        {key: 'note', label: 'Note'},
        {key: 'is_active', label: 'Active'},
        {key: 'menu', label: 'Menu'}
      ],
      isTableDataLoading:  false,
      modalName: ''
    }
  },
  created() {
    this.listAllRgc()
  },
  methods: {
    openModal (modalName, data) {
      this.modalName = modalName
      this.dataObjectSelected = data
      this.dataObjectSelectedClone = {...data}
      this.$refs['rgc-modal'].show()
    },
    closeModal () {
      this.$refs['rgc-modal'].hide()
    },

    listAllRgc () {
      let self = this
      this.isTableDataLoading = true
      this.runApi(rgcService.listAllRgc(), (result) => {
        self.dataObjectList = result.data
        self.isTableDataLoading = false
      })
    },
    updateRgc () {
      let self = this
      this.runApi(rgcService.updateRgc(this.dataObjectSelectedClone), (result) => {
        this.listAllRgc()
      }, null, function () {
        self.closeModal()
      }, true)
    },
    deleteRgc () {
      let self = this
      this.runApi(rgcService.deleteRgc(this.dataObjectSelectedClone['id']), (result) => {
        this.removeRowDataByIds(this.dataObjectSelectedClone['id'])
      }, null, function () {
        self.closeModal()
      }, true)
    },
    insertNewRgc () {
      let self = this
      this.runApi(rgcService.insertNewRgc(this.dataObjectSelectedClone), (result) => {
        this.listAllRgc()
      }, null, function () {
        self.closeModal()
      }, true)
    }
  }
}
</script>
